




















































































































































































































































































































































































































































































































































































































































































































































































// .el-table--small td, .el-table--small th {
//   padding: 0
// }
.el-table {
  .el-table__fixed-right {
    height: auto !important; // 此处的important表示优先于element.style
    bottom: 12px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
  }
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  min-width: 64px;
  height: 82px;
  display: flex;
}

.el-upload-list.el-upload-list--picture-card {
  display: flex;
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox {
  .el-select {
    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}

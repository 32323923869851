




















































































































































































































































































































































































































































































































































































































































































































































































































.hide .el-upload--picture-card {
  display: none;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
